import React, { ReactElement } from 'react'

import classNames from '../../antd/_util/classNames'
import Icon from '../icon'
import Input from './Input'

type Props = {
  placeholder?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSearch?: (query: string) => void
  className?: string
  suffix?: string
  tabIndex?: number
  value?: string
  defaultValue?: string
}

export default function Search(props: Props): ReactElement | null {
  const prefixCls = 'ant-input-search'
  const input = React.useRef<HTMLInputElement>(null)

  const onSearch = () => {
    if (!input.current) {
      return
    }
    if (props.onSearch) {
      props.onSearch(input.current.value)
    }
    input.current.focus()
  }

  const { className, suffix, ...others } = props
  delete others.onSearch
  const searchIcon = <Icon className={`${prefixCls}-icon`} onClick={onSearch} type="magnifyingGlass" key="searchIcon" />
  const searchSuffix = suffix ? [suffix, searchIcon] : searchIcon
  return (
    <Input
      onPressEnter={onSearch}
      {...others}
      className={classNames(prefixCls, className)}
      suffix={searchSuffix}
      forwardRef={input}
    />
  )
}
