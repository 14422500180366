import { isSameDay } from 'date-fns'
import React, { createRef, ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import Employee from '../../model/employee'
import Swipe, { SwipePayOutType } from '../../model/swipe'
import { SwipeReducer } from '../../reducers/swipes'
import { SwipeStatusReducer } from '../../reducers/swipeStatus'
import { paths } from '../../routes'
import { formatDate, getDate } from '../../utils/date-utils'
import { formatDay, numberToDay } from '../../utils/day-utils'
import { getPlatform } from '../../utils/device-utils'
import { forceParseInputNumber, formatCurrency } from '../../utils/number-utils'
import { hasSwipe } from '../../utils/swipe-utils'
import { t, tx } from '../../utils/translation-utils'
import Card from '../antd/card'
import Modal from '../antd/modal'
import Radio, { Group as RadioGroup } from '../antd/radio'
import Button from '../elements/button'
import DumbLink from '../elements/DumbLink'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'
import Slider from './Slider'
import SwipeFeeExplanation from './SwipeFeeExplanation'

import './Swipe.css'

type Props = {
  subsection?: string
  company: Company
  employee: Employee
  swipes: SwipeReducer
  swipeStatus: SwipeStatusReducer

  getSwipes: (employeeID: string) => void
  createSwipe: (employeeID: string, amount: number, payOut: SwipePayOutType) => Promise<Swipe | void>
  deleteSwipe: (swipeID: string) => Promise<boolean | void>
  getSwipeStatus: (employeeID: string) => void
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
}

type State = {
  swiped: boolean
  swipedApproved?: boolean
  swipedFor?: number
  swipedDone?: boolean
  swipedDate?: Date
}

export default function SwipeComponent(props: Props): ReactElement | null {
  const [deleting, setDeleting] = useState<string[]>([])
  const [currentSelected, setCurrentSelected] = useState(props.swipeStatus.availableAutomatic)
  const [state, setState] = useState<State>({ swiped: false })
  type PayoutState = {
    payOutType: SwipePayOutType
    payOutOptions: SwipePayOutType[]
    instantPayCost: number
  }
  const [payout, setPayout] = useState<PayoutState>(() => {
    const instantPayCost = props.swipeStatus.instantPayCost
    if (instantPayCost === null || instantPayCost === undefined) {
      return {
        payOutType: 'NETS',
        payOutOptions: ['NETS'],
        instantPayCost: 0,
      }
    }
    if (instantPayCost === 0) {
      return {
        payOutType: 'Zimpler',
        payOutOptions: ['Zimpler'], // do not offer them another choice when free
        instantPayCost: 0,
      }
    }
    return {
      payOutType: 'NETS',
      payOutOptions: ['NETS', 'Zimpler'],
      instantPayCost,
    }
  })
  const [showExplanation, setShowExplanation] = useState(false)

  const sliderTextRef = createRef<HTMLSpanElement>()

  const { swipeStatus } = props
  const previousSwipeStatus = usePrevious(swipeStatus)
  useEffect(() => {
    if (previousSwipeStatus && previousSwipeStatus.loading && !swipeStatus.loaded) {
      setCurrentSelected(swipeStatus.availableAutomatic)
    }
  }, [previousSwipeStatus, swipeStatus])

  const remove = (swipeID: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      if (window.confirm(t('swipe.want_to_cancel'))) {
        setDeleting((prev) => [...prev, swipeID])
        props.deleteSwipe(swipeID).then(() => {
          if (!props.swipeStatus.loading) {
            props.getSwipeStatus(props.employee.id)
          }
          setDeleting([])
        })
      }
    }
  }

  type StateType = 'pending' | 'approved' | 'paid'
  type SwipeRow = {
    id: string
    state: StateType
    className: string
    date: string
    amount: string
    paid: boolean
    deletable: boolean
  }

  const getSwipes = () => {
    const states = props.swipes.swipes
      .filter((swipe) => !deleting.some((id) => swipe.id === id))
      .reduce(
        (states: Record<StateType, SwipeRow[]>, swipe) => {
          let state: StateType = 'pending'
          if (swipe.approved) {
            state = 'approved'
          }
          if (swipe.paid) {
            state = 'paid'
          }
          if (!states[state]) {
            states[state] = []
          }
          let className = 'swipe-default'
          if (swipe.approved) {
            className = 'swipe-approved'
          }
          const date = swipe.paymentDate ? formatDate(swipe.paymentDate) : formatDate(swipe.createdAt)
          const amount = formatCurrency(swipe.amount, 2)
          states[state].push({
            id: swipe.id,
            state,
            className,
            date,
            amount,
            paid: swipe.paid,
            deletable: swipe.deletable,
          })
          return states
        },
        { pending: [], approved: [], paid: [] }
      )
    const result: { state: StateType; swipes: SwipeRow[] }[] = []
    for (const state in states) {
      const list = states[state as StateType]
      if (list.length > 0) {
        result.push({ state: state as StateType, swipes: states[state as StateType] })
      }
    }
    return result
  }

  const formatSwipeState = (state: StateType) => {
    switch (state) {
      case 'pending':
        return t('swipe.need_approval')
      case 'approved':
        return t('swipe.pending')
      case 'paid':
        return t('swipe.paid')
      default:
        return t('common.unknown')
    }
  }

  const success = () => {
    const swipe = {
      employeeID: props.employee.id,
      amount: forceParseInputNumber(currentSelected),
      payOut: payout.payOutType,
    }
    setState({ swiped: true, swipedFor: currentSelected, swipedDone: false })
    props.createSwipe(swipe.employeeID, swipe.amount, swipe.payOut).then((res) => {
      if (res) {
        setState((prev) => ({
          ...prev,
          swipedDone: true,
          swipedApproved: res.approved,
          swipedDate: res.receiveDate ? getDate(res.receiveDate) : undefined,
        }))
      }
    })
  }

  const setValue = (value: number) => {
    let currentSelected
    if (props.swipeStatus.available < 150) {
      // below 150, just select what the user selected
      currentSelected = Math.min(value, props.swipeStatus.available)
    } else if (value > props.swipeStatus.available - 50) {
      currentSelected = props.swipeStatus.available
    } else {
      const swipeLimit = props.swipeStatus.availableAutomatic
      const threshold = props.swipeStatus.available / 50
      if (swipeLimit + threshold > value && value > swipeLimit) {
        currentSelected = swipeLimit
      } else {
        currentSelected = value
      }
    }
    setCurrentSelected(currentSelected)
    if (sliderTextRef.current) {
      sliderTextRef.current.innerText = formatCurrency(currentSelected, 2)
    }
  }

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setValue(parseInt(e.target.value))
  }

  const handlePayOutChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setPayout((prev) => ({ ...prev, payOutType: e.target.value as SwipePayOutType }))
  }

  const getApprovedWhen = () => {
    if (!state.swipedApproved) {
      return ''
    }
    const date = state.swipedDate
    if (date) {
      if (isSameDay(date, getDate())) {
        return t('swipe.today')
      }
      return t('swipe.on_day', { day: formatDay(numberToDay(date.getDay())) })
    }
    return t('swipe.unknown_day')
  }
  const showHistory = () => {
    return props.subsection === 'history'
  }

  const displayFee = () => {
    if (!props.swipeStatus.willChargeFee) {
      return null
    }
    const baseFee = props.swipeStatus.swipeFeeCost ?? 0
    if (baseFee <= 0) {
      return null
    }
    let currentFee = 0
    switch (props.swipeStatus.swipeFeeCostType) {
      case 'Fixed':
        currentFee = baseFee
        break
      case 'Percentage':
        currentFee = (baseFee / 100) * currentSelected
        break
    }

    const baseCap = props.swipeStatus.swipeFeeCostCap ?? 0
    if (baseCap > 0) {
      if (currentFee > baseCap) {
        currentFee = baseCap
      }
    }

    if (currentFee <= 0) {
      return null
    }

    return (
      <p className="swipe-fee-note">
        {tx('swipe.fee_note', {
          fee: <strong>{formatCurrency(currentFee, 2)}</strong>,
          link: <DumbLink onClick={() => setShowExplanation(true)}>{t('swipe.fee_note.more_information')}</DumbLink>,
        })}
      </p>
    )
  }

  if (!hasSwipe(props.company, props.employee)) {
    // somehow got here, when they do not have Swipe
    jsBrowserHistory.push('/')
    return null
  }

  if (state.swiped) {
    return (
      <div className="swipe-overlay">
        <div className="swipe-overlay-title">
          {t('swipe.just_swiped', { amount: formatCurrency(state.swipedFor, 2) })}
        </div>
        {state.swipedDone && (
          <div className="swipe-overlay-text">
            {state.swipedApproved
              ? t('swipe.just_swiped_approved', { when: getApprovedWhen() })
              : t('swipe.just_swiped_needs_approval')}
          </div>
        )}
        {state.swipedApproved && <div className="swipe-overlay-text">{t('swipe.just_swiped_on_the_way')}</div>}
        <Button size={'large'} className={'swipe-overlay-button'} type="primary">
          <Link to={'/'}>{t('swipe.just_swiped_return')}</Link>
        </Button>
      </div>
    )
  }
  const swipes = getSwipes()
  const canSwipe = props.swipeStatus.canSwipeNow
  const swipeLimit = props.swipeStatus.availableAutomatic

  if (!canSwipe || showHistory()) {
    return (
      <div className={'swipes registrations-list platform-' + getPlatform()}>
        {['pending', 'approved', 'paid'].map((state) => {
          const row = swipes.find((v) => v.state === state)
          if (!row || row.swipes.length === 0) {
            return null
          }
          return (
            <div key={row.state}>
              <div className="swipe-header">{formatSwipeState(row.state)}</div>
              {row.swipes.map((swipe) => {
                return (
                  <div key={swipe.id} className="registration-row">
                    <Card>
                      <div className="registration-row-info no-click">
                        <div className="swipe-date">{swipe.date}</div>
                        <div className="swipe-description">{swipe.amount}</div>
                      </div>
                      {swipe.deletable && <span onClick={remove(swipe.id)} className="registration-row-delete" />}
                    </Card>
                  </div>
                )
              })}
            </div>
          )
        })}
        {swipes.length === 0 && <div className="content-text">{t('swipe.no_swipes_yet')}</div>}
        {canSwipe && props.swipeStatus.available > 0 && (
          <Link to={'/' + paths.SWIPE} className="swipes-start">
            {t('swipe.go_to_swipe', { amount: formatCurrency(props.swipeStatus.available, 2) })}
          </Link>
        )}
      </div>
    )
  }

  const company = props.company
  return (
    <div className={'swipe platform-' + getPlatform()}>
      {(deleting.length > 0 ||
        !props.swipes.loaded ||
        props.swipes.saving ||
        (canSwipe && !props.swipeStatus.loaded)) && (
        <LoadingOverlay text={!props.swipeStatus.loaded ? t('swipe.calculating') : ''} />
      )}
      {canSwipe && props.swipeStatus.loaded && props.swipeStatus.available > 0 && (
        <div>
          <div className={'swipe-introduction'}>
            {swipes.length > 0 && (
              <Link to={'/' + paths.SWIPE + '/' + paths.HISTORY} className="swipe-history">
                {t('swipe.go_to_history')}
              </Link>
            )}
            <p>{t('swipe.swipe_introduction_available', { amount: formatCurrency(props.swipeStatus.available) })}</p>
            <div className="swipe-amount-container">
              <div className="swipe-amount-max" onClick={() => setValue(props.swipeStatus.available)}>
                {formatCurrency(props.swipeStatus.available, 2)}
              </div>
              <div className="swipe-amount-min">{formatCurrency(0, 2)}</div>
              <input
                type="range"
                className="select-amount-slider"
                onChange={handleValueChange}
                value={currentSelected}
                min={props.swipeStatus.available < 150 ? 1 : 50}
                max={
                  props.swipeStatus.available > 150
                    ? Math.floor(props.swipeStatus.available / 50) * 50
                    : props.swipeStatus.available
                }
                step={props.swipeStatus.available < 150 ? 1 : 50}
              />
              <p className="swipe-amount-description">{t('swipe.swipe_amount_adjustment_instructions')}</p>
            </div>
          </div>
          <div className="swipe-slider-container">
            {currentSelected > swipeLimit && (
              <p className="swipe-above-limit">
                {t('swipe.over_limit', {
                  amount: formatCurrency(company.periodicLimitForImmediatePayOut, 2),
                })}
              </p>
            )}
            {displayFee()}
            <div className="slider-container">
              <Slider onReached={success} disabled={currentSelected <= 0} />
              <span className="slider-text" ref={sliderTextRef}>
                {formatCurrency(currentSelected, 2)}
              </span>
            </div>
            <p>{currentSelected > 0 ? t('swipe.swipe_instructions') : t('swipe.swipe_select_amount')}</p>
          </div>
        </div>
      )}
      {canSwipe && payout.payOutOptions.length === 2 && (
        <div className={'swipe-payout-option'}>
          <p>{t('swipe.payout_option_instructions_1', { cost: formatCurrency(payout.instantPayCost, 2) })}</p>
          <p>{t('swipe.payout_option_instructions_2')}</p>
          <RadioGroup onChange={handlePayOutChange} value={payout.payOutType}>
            <Radio value={'Zimpler'}>
              {t('swipe.instant_choice', { cost: formatCurrency(payout.instantPayCost, 2) })}
            </Radio>
            <Radio value={'NETS'}>{t('swipe.immediate_choice')}</Radio>
          </RadioGroup>
        </div>
      )}
      {canSwipe && (!props.swipeStatus.available || props.swipeStatus.available === 0.0) && (
        <div className={'swipe-introduction'}>{t('swipe.swipe_nothing_available')}</div>
      )}

      <Modal
        key={`info`}
        visible={showExplanation}
        closable={true}
        onOk={() => setShowExplanation(false)}
        onCancel={() => setShowExplanation(false)}
        footer={null}
      >
        <SwipeFeeExplanation swipeStatus={props.swipeStatus} closeModal={() => setShowExplanation(false)} />
      </Modal>
    </div>
  )
}
