import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import { ReactComponent as SalaryLogo } from '../../assets/logo.svg'
import back from '../../images/icon-arrow-left-white-30x30.png'
import Employee from '../../model/employee'
import { paths } from '../../routes'
import { RoutePropsLocation } from '../../utils/route-utils'
import { t } from '../../utils/translation-utils'
import UserImage from '../elements/UserImage'
import jsBrowserHistory from '../widgets/jsBrowserHistory'

import './Header.css'

type Props = {
  location: RoutePropsLocation
  employee?: Employee
}

export default function Header(props: Props): ReactElement | null {
  const handleBack = (e: React.MouseEvent) => {
    e.preventDefault()

    jsBrowserHistory.goBack()
  }
  const canGoBack = () => {
    const path = props.location.pathname
    if (path === '/') {
      // Dashboard/main page
      return false
    }
    if (path === '/login') {
      // Login page
      return false
    }
    if (path.match(/^\/signup\//)) {
      // Signup page
      return false
    }
    return true
  }

  return (
    <header className="header">
      {canGoBack() && (
        <span onClick={handleBack} className="back">
          <img src={back} alt={t('header.go_back')} />
        </span>
      )}
      {!!props.employee && (
        <Link to={'/' + paths.PROFILE} className="profile">
          <UserImage name={props.employee.name} size="medium" />
        </Link>
      )}
      <Link to="/" className="header-logo">
        <SalaryLogo />
      </Link>
    </header>
  )
}
