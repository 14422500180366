import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import CarAllowance, { CarAllowanceMutableFields } from '../../model/carAllowance'
import Company from '../../model/company'
import CostCenter from '../../model/costCenter'
import Department from '../../model/department'
import Employee from '../../model/employee'
import { DateFormat } from '../../model/types'
import { CarAllowanceReducer } from '../../reducers/carAllowances'
import { paths } from '../../routes'
import { formatDate, trimCurrentYear } from '../../utils/date-utils'
import { getPlatform } from '../../utils/device-utils'
import { formatCurrency, formatNumber } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Card from '../antd/card'
import Modal from '../antd/modal'
import Button from '../elements/button'
import NoContractMessage from '../widgets/NoContractMessage'
import PullToRefresh from '../widgets/PullToRefresh'
import CarAllowanceModal from './CarAllowanceModal'

import './CarAllowance.css'

type Props = {
  employee: Employee
  carAllowances: CarAllowanceReducer
  showHistory: boolean
  company: Company
  costCenters: List<CostCenter>
  departments: List<Department>

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getCarAllowances: (employeeID: string, fromDate?: DateFormat, toDate?: DateFormat) => Promise<CarAllowance[] | void>
  createCarAllowances: (carAllowances: CarAllowanceMutableFields[]) => void
  updateCarAllowance: (carAllowance: CarAllowanceMutableFields) => void
  deleteCarAllowance: (carAllowance: CarAllowance) => void
}

export default function CarAllowances(props: Props): ReactElement | null {
  const [deleting, setDeleting] = useState<string[]>([])
  const [modalKey, setModalKey] = useState(1)
  const [editing, setEditing] = useState<string | boolean>(false)

  const { carAllowances } = props
  const previousCarAllowances = usePrevious(carAllowances)

  const setEditVisibility = (id: string | boolean) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setEditing(id)
  }

  useEffect(() => {
    if (previousCarAllowances && previousCarAllowances.saving && !carAllowances.saving) {
      if (!carAllowances.error) {
        setEditVisibility(false)
      }
    }
  })

  const remove = (carAllowance: CarAllowance) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, carAllowance.id])
        props.deleteCarAllowance(carAllowance)
      }
    }
  }

  type CarAllowanceRow = {
    id: string
    className: string
    date: string
    licensePlate: string
    reason: string
    distance: string
    description: string
    approved: boolean
    immutable: boolean
    rate?: string
    original: CarAllowance
  }

  const getCarAllowanceRows = () => {
    const months: Record<string, CarAllowanceRow[]> = {}
    props.carAllowances.carAllowances
      .filter(
        (carAllowance) => carAllowance.settled === props.showHistory && !deleting.some((id) => id === carAllowance.id)
      )
      .forEach((carAllowance) => {
        let month = formatDate(carAllowance.date, 'MMMM yyyy')
        month = month[0].toUpperCase() + month.substring(1)
        if (!months[month]) {
          months[month] = []
        }
        let className = 'registration-row-unapproved'
        if (carAllowance.approved) {
          className = 'registration-row-approved'
        }
        months[month].push({
          id: carAllowance.id,
          className,
          date: trimCurrentYear(formatDate(carAllowance.date)),
          licensePlate: carAllowance.licensePlate,
          reason: carAllowance.reason,
          distance: formatNumber(carAllowance.kilometers, 2) + ' km',
          description: carAllowance.locationFrom + ' - ' + carAllowance.locationTo,
          approved: carAllowance.approved,
          immutable: carAllowance.immutable,
          rate: carAllowance.rate ? formatCurrency(carAllowance.rate, 2) : undefined,
          original: carAllowance,
        })
      })
    const carAllowances = []
    for (const month in months) {
      carAllowances.push({
        month,
        carAllowances: months[month],
      })
    }
    return carAllowances
  }

  const employee = props.employee
  if (!employee.activeContract) {
    return <NoContractMessage employee={employee} />
  }

  const locked = !props.employee.earliestMutableContract
  const carAllowanceRows = getCarAllowanceRows()
  return (
    <div className={'car-allowance registrations-list platform-' + getPlatform()}>
      <PullToRefresh onRefresh={() => props.getCarAllowances(props.employee.id)}>
        {props.showHistory ? (
          <Link to={'/' + paths.CAR_ALLOWANCE} className="car-allowance-history">
            {t('common.go_back')}
          </Link>
        ) : (
          <Link to={'/' + paths.CAR_ALLOWANCE + '/' + paths.HISTORY} className="car-allowance-history">
            {t('common.show_history')}
          </Link>
        )}
        {carAllowanceRows.map((row) => {
          return (
            <div key={row.month}>
              <div className="car-allowances-header">{row.month}</div>
              {row.carAllowances.map((carAllowance) => {
                return (
                  <div key={carAllowance.id} className="registration-row">
                    <Card className={carAllowance.className}>
                      <div className="registration-row-info" onClick={() => setEditVisibility(carAllowance.id)}>
                        <div className="car-allowance-date">
                          {carAllowance.date} ({carAllowance.reason})
                        </div>
                        <div className="car-allowance-distance">
                          {carAllowance.distance}
                          {carAllowance.rate && (
                            <> ({t('car_allowance.table.rate_format', { rate: carAllowance.rate })})</>
                          )}
                        </div>
                        <div className="car-allowance-description">{carAllowance.description}</div>
                      </div>
                      {!carAllowance.immutable && !locked && (
                        <span onClick={remove(carAllowance.original)} className="registration-row-delete" />
                      )}
                    </Card>
                  </div>
                )
              })}
            </div>
          )
        })}
        {carAllowanceRows.length === 0 && (
          <div className="content-text">
            {props.showHistory
              ? t('car_allowance.no_historic_car_allowances')
              : t('car_allowance.no_car_allowances_yet')}
          </div>
        )}
      </PullToRefresh>
      {!props.showHistory && !locked && (
        <Button onClick={() => setEditVisibility(true)} className="registrations-add" type="primary">
          {t('car_allowance.register_car_allowance')}
        </Button>
      )}

      <Modal
        key={modalKey}
        visible={editing !== false}
        closable={true}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        footer={null}
      >
        <CarAllowanceModal
          visible={editing !== false}
          locked={locked} // if no earliestMutableContract, user cannot modify
          employee={props.employee}
          company={props.company}
          costCenters={props.costCenters}
          departments={props.departments}
          carAllowanceID={typeof editing === 'string' ? editing : undefined}
          carAllowances={props.carAllowances}
          createCarAllowances={props.createCarAllowances}
          updateCarAllowance={props.updateCarAllowance}
        />
      </Modal>
    </div>
  )
}
