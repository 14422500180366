import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'

import Company from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import Employee from '../../model/employee'
import LeaveType from '../../model/leaveType'
import Project from '../../model/project'
import SalaryType from '../../model/salaryType'
import Modal from '../antd/modal'
import Button from '../elements/button'
import CardChoiceSwitcherModal from './CardChoiceSwitcherModal'

type Props = {
  employee: Employee
  company: Company
  projects: List<Project>
  leaveTypes: List<LeaveType>
  salaryTypes: List<SalaryType>
  companyFeatures: List<CompanyFeature>

  updateEmployee: (employee: Employee) => void
}

export default function CardChoiceSwitcher(props: Props): ReactElement | null {
  const [showModal, setShowModal] = useState(false)
  const [modalKey, setModalKey] = useState(1)

  const doShowModal = (toggle: boolean) => {
    setModalKey((prev) => prev + 1)
    setShowModal(toggle)
  }

  return (
    <>
      <Button.Icon
        type="paperWithPencil"
        className="dashboard-choice-switcher-toggle"
        onClick={() => doShowModal(true)}
      />

      <Modal
        key={modalKey}
        visible={showModal}
        onOk={() => doShowModal(false)}
        onCancel={() => doShowModal(false)}
        footer={null}
      >
        <CardChoiceSwitcherModal
          employee={props.employee}
          company={props.company}
          projects={props.projects}
          leaveTypes={props.leaveTypes}
          salaryTypes={props.salaryTypes}
          companyFeatures={props.companyFeatures}
          updateEmployee={props.updateEmployee}
          onClose={() => doShowModal(false)}
        />
      </Modal>
    </>
  )
}
