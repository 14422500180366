import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../model/company'
import CostCenter from '../../model/costCenter'
import Department from '../../model/department'
import Employee from '../../model/employee'
import Project from '../../model/project'
import { SalaryDefinition } from '../../model/remuneration'
import SalaryType from '../../model/salaryType'
import { DateFormat } from '../../model/types'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import CompanySetting from '../../types/company-setting'
import SalaryClass from '../../types/salary-class'
import { formatAPIDate, formatDate, getDate } from '../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { getCurrentLocale } from '../../utils/language-utils'
import {
  forceParseInputNumber,
  formatCurrency,
  formatInputAsMinutes,
  formatInputNumber,
  formatMinutesAsTime,
  parseInputNumber,
  parseTimeAsMinutes,
} from '../../utils/number-utils'
import { setByPath } from '../../utils/object-utils'
import { projectFilter } from '../../utils/projects-utils'
import { t, translateGroupTitle } from '../../utils/translation-utils'
import { validateDecimal } from '../../utils/validation-utils'
import Form from '../antd/form'
import Radio, { Group as RadioGroup } from '../antd/radio'
import Select from '../antd/select'
import Button from '../elements/button'
import DatePicker from '../elements/date-picker'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import RegistrationCostCenter, { NO_COST_CENTER } from '../elements/RegistrationCostCenter'
import { TimeRegistrationEditMode } from '../types'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  locked: boolean
  timeRegistrationID?: string
  mode: TimeRegistrationEditMode
  company: Company
  employee: Employee
  timeRegistrations: TimeRegistrationReducer
  salaryTypes: List<SalaryType>
  costCenters: List<CostCenter>
  departments: List<Department>
  projects: List<Project>
  includeDateRange?: [Date, Date]
  suggestedDate?: Date
}

type Fields = {
  salaryTypeID?: string
  projectID?: string
  costCenterID?: string
  date: Date
  startTime?: string
  endTime?: string
  breakMinutes?: string
  immutable: boolean
  timeBoxUsed: boolean
  hours?: string
  note?: string
}

export type ResultFields = {
  salaryTypeID?: string
  projectID?: string
  costCenterID?: string
  date: DateFormat
  start?: number
  end?: number
  hours: number
  note: string
}

function TimeRegistrationModalForm(props: Props & FormComponentProps<Fields, ResultFields>): ReactElement | null {
  const doTimeBasedRegistration = () => {
    if (!props.employee.activeContract) {
      return false
    }
    if (props.mode === 'Flex' || props.mode === 'Overtime') {
      return false
    }
    if (props.mode === 'Work Hours') {
      return props.company.settingsEnabled.some((setting) => setting === CompanySetting.REGISTER_WORK_HOURS_START_END)
    }
    if (
      props.company.settingsEnabled.some((setting) => setting === CompanySetting.REGISTER_TIME_REGISTRATION_START_END)
    ) {
      return true
    }
    return props.employee.activeContract.remuneration.salary.some((salary) => {
      const salaryType = props.salaryTypes.find((type) => type.id === salary.salaryTypeID)
      return salaryType && salaryType.supplements && salaryType.supplements.length > 0
    })
  }

  const getTypes = (): SalaryDefinition[] => {
    if (!props.employee.activeContract || props.mode === 'Flex' || props.mode === 'Overtime') {
      return []
    }
    const exists: string[] = []
    return props.employee.activeContract.remuneration.salary
      .filter((salary) =>
        props.salaryTypes.some((type) => type.id === salary.salaryTypeID && type.class === SalaryClass.HOURLY)
      )
      .filter((salary) => {
        if (exists.indexOf(salary.salaryTypeID) === -1) {
          exists.push(salary.salaryTypeID)
          return true
        }
        return false
      })
  }

  const getProjects = (): Project[] => {
    return props.projects.filter((p) => projectFilter(p, props.employee, props.getFieldValue('date'))).toArray()
  }

  const { decorateField, getFieldValue } = props

  if (getFieldValue('immutable') || props.locked) {
    let type = t('common.unknown')
    if (props.employee.activeContract) {
      const salaryRow = props.employee.activeContract.remuneration.salary.find(
        (row) => row.salaryTypeID === getFieldValue('salaryTypeID')
      )
      if (salaryRow) {
        type = translateGroupTitle(salaryRow)
      }
    }
    const project = props.projects.find((p) => p.id === getFieldValue('projectID'))
    return (
      <div>
        {props.getFormError()}
        <Form.Item>
          <label>{t('common.date')}</label>
          {formatDate(getFieldValue('date'))}
        </Form.Item>
        <Form.Item>
          <label>{t('time_registration.number_of_hours')}</label>
          {getFieldValue('hours')} {t('unit.hours', { count: forceParseInputNumber(getFieldValue('hours')) })}
        </Form.Item>
        {props.mode !== 'Project Hours' && (
          <Form.Item>
            <label>{t('common.type')}</label>
            {type}
          </Form.Item>
        )}
        {project && (
          <Form.Item>
            <label>{t('time_registration.project')}</label>
            {project.name}
          </Form.Item>
        )}
        <Form.Item>
          <label>{t('common.note')}</label>
          {getFieldValue('note')}
        </Form.Item>
      </div>
    )
  }

  const timeBasedRegistration = doTimeBasedRegistration()
  const includeProjects =
    props.mode === 'Project Hours' ||
    (props.mode === 'Hours' && props.projects.some((p) => projectFilter(p, props.employee)))

  return (
    <div>
      {props.getFormError()}
      {decorateField('date', {
        placeholder: t('common.date'),
        validate: (val) => {
          if (!val) {
            return t('validation.date_is_required')
          }
          return null
        },
      })(
        <DatePicker
          allowClear={false}
          tabIndex={3}
          style={{ width: '100%' }}
          includeDateRange={props.includeDateRange}
        />
      )}
      {timeBasedRegistration && (
        <Row>
          <Col span={12}>
            {decorateField('startTime', {
              placeholder: t('common.start'),
              validate: (val) => {
                if (!val) {
                  return t('validation.start_is_required')
                }
                if (!val.match(/^([0-9]{4}|[0-9:]{5})$/)) {
                  return t('validation.start_is_invalid')
                }
                if (parseTimeAsMinutes(val) > 1440) {
                  return t('validation.start_is_invalid')
                }
                const endTime = getFieldValue('endTime')
                if (endTime && parseTimeAsMinutes(val) >= parseTimeAsMinutes(endTime)) {
                  return t('validation.start_must_be_before_end')
                }
                return null
              },
            })(<Input tabIndex={4} />)}
            <span className="ant-form-extra">Format HH:MM, fx 16:15</span>
          </Col>
          <Col span={12}>
            {decorateField('endTime', {
              placeholder: t('common.end'),
              validate: (val) => {
                if (!val) {
                  return t('validation.end_is_required')
                }
                if (!val.match(/^([0-9]{4}|[0-9:]{5})$/)) {
                  return t('validation.end_is_invalid')
                }
                if (parseTimeAsMinutes(val) > 1440) {
                  return t('validation.end_is_invalid')
                }
                const startTime = getFieldValue('startTime')
                if (startTime && parseTimeAsMinutes(startTime) >= parseTimeAsMinutes(val)) {
                  return t('validation.end_must_be_after_start')
                }
                return null
              },
            })(<Input tabIndex={5} />)}
          </Col>
        </Row>
      )}
      {timeBasedRegistration && (
        <Row>
          <Col span={24}>
            {decorateField('breakMinutes', {
              placeholder: t('time_registration.break_minutes'),
              suffix: t('unit.minutes.many') + ' ',
              validate: (val) => {
                if (!val) {
                  return null
                }
                if (!validateDecimal(val)) {
                  return t('validation.break_minutes_is_invalid')
                }
                const num = forceParseInputNumber(val)
                if (num % 1 !== 0) {
                  return t('validation.break_minutes_must_be_integer')
                }
                const startTime = getFieldValue('startTime')
                const endTime = getFieldValue('endTime')
                if (startTime && endTime && parseTimeAsMinutes(endTime) - parseTimeAsMinutes(startTime) <= num) {
                  return t('validation.break_minutes_longer_than_possible')
                }
                return null
              },
            })(<Input tabIndex={6} inputMode="numeric" step="1" lang={getCurrentLocale()} />)}
          </Col>
        </Row>
      )}
      {!timeBasedRegistration &&
        decorateField('hours', {
          placeholder: t('time_registration.number_of_hours'),
          suffix: t('unit.hours.many') + ' ',
          validate: (val) => {
            if (!val) {
              return t('validation.number_of_hours_is_required')
            }
            if (!validateDecimal(val)) {
              return t('validation.number_of_hours_is_invalid')
            }
            return null
          },
        })(<Input tabIndex={7} inputMode="decimal" lang={getCurrentLocale()} />)}
      {props.mode === 'Hours' &&
        decorateField('salaryTypeID', {
          placeholder: t('common.type'),
          validate: (val) => (!val ? t('validation.type_must_be_chosen') : null),
        })(
          <Select dropdownMatchSelectWidth={false} tabIndex={5}>
            {getTypes().map((row) => {
              return (
                <Select.Option key={row.salaryTypeID} value={row.salaryTypeID}>
                  {translateGroupTitle(row)} ({formatCurrency(row.rate, 2)})
                </Select.Option>
              )
            })}
          </Select>
        )}
      {includeProjects &&
        decorateField('projectID', {
          placeholder: t('time_registration.project'),
          validate: (val) => {
            if (props.mode === 'Project Hours' && !val) {
              return t('validation.project_must_be_chosen')
            }
            return null
          },
        })(
          <Select dropdownMatchSelectWidth={false} tabIndex={6}>
            {getProjects().map((row) => {
              return (
                <Select.Option key={row.id} value={row.id}>
                  {row.name}
                </Select.Option>
              )
            })}
          </Select>
        )}
      {(props.mode === 'Flex' || props.mode === 'Overtime') &&
        decorateField('timeBoxUsed', {
          skipWrapper: true,
          skipLabel: true,
        })(
          <RadioGroup style={{ marginBottom: '10px' }}>
            <Radio value={true}>
              {props.mode === 'Flex' ? t('time_registration.flex_used') : t('time_registration.overtime_used')}
            </Radio>
            <Radio value={false}>
              {props.mode === 'Flex' ? t('time_registration.flex_earned') : t('time_registration.overtime_earned')}
            </Radio>
          </RadioGroup>
        )}
      {decorateField('note', {
        placeholder: t('common.note'),
      })(<Input tabIndex={8} />)}
      <RegistrationCostCenter
        tabIndex={9}
        company={props.company}
        costCenters={props.costCenters}
        departments={props.departments}
        decorateField={decorateField}
        getFieldValue={getFieldValue}
      />
      <Form.Item>
        <Button htmlType="submit" size="large" type="primary" tabIndex={10}>
          {props.mode === 'Flex'
            ? t('time_registration.register_flex_time')
            : props.mode === 'Overtime'
            ? t('time_registration.register_overtime')
            : props.mode === 'Project Hours'
            ? t('time_registration.register_project_time')
            : props.mode === 'Work Hours'
            ? t('time_registration.register_work_hours')
            : t('time_registration.register_time')}
        </Button>
      </Form.Item>
      {props.timeRegistrations.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, ResultFields>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      salaryTypeID: undefined,
      costCenterID: NO_COST_CENTER,
      date: props.suggestedDate ?? getDate(),
      immutable: false,
      timeBoxUsed: true,
    }
    if (props.mode === 'Hours') {
      // just pick the first hourly salary line on the contract
      const salary = props.employee.activeContract?.remuneration.salary.filter((def) =>
        props.salaryTypes.some((t) => t.id === def.salaryTypeID && t.class === SalaryClass.HOURLY)
      )
      if (salary && salary.length > 0) {
        fields.salaryTypeID = salary[0].salaryTypeID
      }
    }
    const timeRegistration = props.timeRegistrations.timeRegistrations.find(
      (timeRegistration) => timeRegistration.id === props.timeRegistrationID
    )
    if (timeRegistration) {
      fields.date = getDate(timeRegistration.date)
      if (timeRegistration.start) {
        fields.startTime = formatMinutesAsTime(timeRegistration.start)
        fields.breakMinutes = '0'
        const hours = timeRegistration.hours || 0
        if (timeRegistration.end) {
          fields.endTime = formatMinutesAsTime(timeRegistration.end)
          fields.breakMinutes = formatInputNumber(
            ((timeRegistration.end - timeRegistration.start) / 60 - hours) * 60,
            0
          )
        } else {
          fields.endTime = formatMinutesAsTime(timeRegistration.start + hours * 60)
        }
      } else {
        if (props.mode === 'Flex' || props.mode === 'Overtime') {
          fields.hours = formatInputNumber(Math.abs(timeRegistration.minutes || 0) / 60)
        } else {
          fields.hours = formatInputNumber(timeRegistration.hours)
        }
      }
      fields.salaryTypeID = timeRegistration.salaryTypeID
      fields.note = timeRegistration.note
      fields.immutable = timeRegistration.immutable
      fields.costCenterID = timeRegistration.costCenterID ? timeRegistration.costCenterID : NO_COST_CENTER
      if (props.mode === 'Flex' || props.mode === 'Overtime') {
        fields.timeBoxUsed = (timeRegistration.minutes || 0) > 0
      }
    }
    return fields
  },
  onChange: (key, val, allValues, options) => {
    const values = {}
    switch (key) {
      case 'startTime':
      case 'endTime':
        if (val && typeof val === 'string') {
          if (options.trigger === 'onBlur') {
            setByPath(values, key, formatInputAsMinutes(val as string))
          } else {
            setByPath(values, key, val.replace(/[ ]/g, ''))
          }
        } else {
          setByPath(values, key, val)
        }
        break
      case 'hours':
        setByPath(
          values,
          key,
          formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }), 2)
        )
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => {
    const result: ResultFields = {
      salaryTypeID: values.salaryTypeID,
      projectID: values.projectID,
      costCenterID: values.costCenterID === NO_COST_CENTER ? undefined : values.costCenterID,
      date: formatAPIDate(values.date),
      hours: forceParseInputNumber(values.hours),
      note: values.note || '',
    }
    if (values.startTime && values.endTime) {
      result.start = parseTimeAsMinutes(values.startTime)
      result.end = parseTimeAsMinutes(values.endTime)
      result.hours =
        (parseTimeAsMinutes(values.endTime) - result.start - forceParseInputNumber(values.breakMinutes)) / 60
    }
    if (!values.timeBoxUsed) {
      result.hours = -result.hours
    }
    return result
  },
})(TimeRegistrationModalForm)
