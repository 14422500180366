import { startOfDay } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../model/company'
import TimeRegistration from '../../model/timeRegistration'
import CompanySetting from '../../types/company-setting'
import { formatAPIDate, formatDate, getDate } from '../../utils/date-utils'
import { formatDay, numberToDay } from '../../utils/day-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import {
  forceParseInputNumber,
  formatInputAsMinutes,
  formatInputNumber,
  formatMinutesAsTime,
  parseInputNumber,
  parseTimeAsMinutes,
} from '../../utils/number-utils'
import { t, tx } from '../../utils/translation-utils'
import Button from '../elements/button'
import { Col, Row } from '../elements/grid'
import Icon from '../elements/icon'
import Input from '../elements/input'

type Props = {
  company: Company
  timeRegistrations: List<TimeRegistration>
}

type Fields = {
  hours?: string
  startTime?: string
  endTime?: string
  breakMinutes?: string
}

export type WorkHoursCardResult = {
  hours: number
  start?: number
  end?: number
}

function WorkHoursCardForm(props: Props & FormComponentProps<Fields, WorkHoursCardResult>): ReactElement | null {
  const { decorateField, getFieldValue } = props

  const doTimeBasedRegistration = (): boolean => {
    if (!!props.getFieldValue(`startTime`) || !!props.getFieldValue(`endTime`)) {
      // one of the registrations is set
      return true
    }
    return props.company.settingsEnabled.some((setting) => setting === CompanySetting.REGISTER_WORK_HOURS_START_END)
  }

  const remove = () => {
    return () => {
      props.setFieldValue('hours', undefined)
      props.setFieldValue('startTime', undefined)
      props.setFieldValue('endTime', undefined)
      props.setFieldValue('breakMinutes', undefined)
    }
  }

  const today = startOfDay(getDate())

  return (
    <div className="work-hours-day-form">
      <p>
        {tx('dashboard.card_work_hours.form.intro', {
          day: (
            <strong>
              {t('dashboard.card_work_hours.form.intro.day', {
                day: formatDay(numberToDay(today.getDay())),
                date: formatDate(today),
              })}
            </strong>
          ),
        })}
      </p>
      {!doTimeBasedRegistration() && (
        <>
          <Row>
            <Col span={24}>
              {decorateField('hours', {
                placeholder: t('dashboard.card_work_hours.form.hours'),
                suffix: t('dashboard.card_work_hours.form.hours.suffix'),
              })(<Input />)}
            </Col>
          </Row>
        </>
      )}
      {doTimeBasedRegistration() && (
        <>
          <Row>
            <Col span={8}>
              {decorateField('startTime', {
                placeholder: t('dashboard.card_work_hours.form.start_time'),
              })(<Input />)}
            </Col>
            <Col span={8}>
              {decorateField('endTime', {
                placeholder: t('dashboard.card_work_hours.form.end_time'),
              })(<Input />)}
            </Col>
            <Col span={6}>
              {decorateField('breakMinutes', {
                placeholder: t('dashboard.card_work_hours.form.break_minutes'),
                suffix: t('dashboard.card_work_hours.form.break_minutes.suffix'),
              })(<Input />)}
            </Col>
            <Col span={2} style={{ paddingTop: '34px', textAlign: 'right' }}>
              {(!!getFieldValue(`startTime`) || !!getFieldValue(`endTime`) || !!getFieldValue(`breakMinutes`)) && (
                <span onClick={remove()} style={{ cursor: 'pointer' }}>
                  <Icon type={'xSign'} />
                </span>
              )}
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="primary">
            {t('dashboard.card_work_hours.form.submit')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, WorkHoursCardResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {}
    const today = formatAPIDate(startOfDay(getDate()))
    const registration = props.timeRegistrations.find((reg) => reg.class === 'Work Hours' && reg.date === today)
    if (registration) {
      fields.hours = formatInputNumber(registration.hours)
      if (registration.start !== undefined) {
        fields.startTime = formatMinutesAsTime(registration.start)
        fields.breakMinutes = '0'
        if (registration.end !== undefined) {
          fields.endTime = formatMinutesAsTime(registration.end)
          fields.breakMinutes = formatInputNumber(
            ((registration.end - registration.start) / 60 - (registration.hours ? registration.hours : 0)) * 60,
            0
          )
        }
      }
    }
    return fields
  },
  onChange: (key, val, allValues, options) => {
    const values: Partial<Fields> = {}
    switch (key) {
      case 'startTime':
      case 'endTime':
        if (options.trigger === 'onBlur') {
          values[key] = formatInputAsMinutes(val as string)
        } else {
          values[key] = (val as string).replace(/[ ]/g, '')
        }
        break
      case 'hours':
        values[key] = formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }))
        break
      default:
        values[key] = val
    }
    return values
  },
  onSubmit: (values) => {
    const result: WorkHoursCardResult = {
      hours: forceParseInputNumber(values.hours),
    }
    if (values.startTime) {
      result.start = parseTimeAsMinutes(values.startTime)
      if (values.endTime) {
        result.end = parseTimeAsMinutes(values.endTime)
        result.hours = (result.end - result.start - forceParseInputNumber(values.breakMinutes)) / 60
      }
    }
    return result
  },
})(WorkHoursCardForm)
